import React from 'react';
import {
    BooleanField,
    BooleanInput,
    CloneButton,
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    FormTab,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';

export const ThemeList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="template_id" reference="templates">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="title"/>
            <NumberField source="template_version"/>
            <BooleanField source="published"/>
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
            <EditButton/>
            <CloneButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const ThemeEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="General">
                <ReferenceInput source="template_id" reference="templates"><SelectInput
                    optionText="name"/></ReferenceInput>
                <ReferenceInput source="theme_pricing_id" reference="theme-pricings"><SelectInput
                    optionText="title"/></ReferenceInput>
                <BooleanInput source="published"/>
                <TextInput source="title"/>
                <NumberInput source="template_version"/>
                <TextInput source="description"/>
                <TextInput source="label.de" label="Label DE"/>
                <TextInput source="label.en" label="Label EN"/>
                <TextInput source="label.fr" label="Label (FR)"/>
                <TextInput source="label.nl" label="Label (NL)"/>
            </FormTab>
            <FormTab label="Attributes">
                <ReferenceArrayInput source="color_ids" reference="colors" label="Colors">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="style_ids" reference="styles" label="Styles">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="tag_ids" reference="tags" label="Tags">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="category_ids" reference="categories" label="Categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Localizations">
                <ReferenceManyField label="Localizations" reference="theme-localizations" target="theme_id"
                                    fullWidth={true}>
                    <Datagrid>
                        <TextField source="title"/>
                        <TextField source="language"/>
                        <BooleanField source="published"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ThemeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="template_id" reference="templates"><SelectInput optionText="name"/></ReferenceInput>
            <ReferenceInput source="theme_pricing_id" reference="theme-pricings"><SelectInput
                optionText="title"/></ReferenceInput>
            <TextInput source="title"/>
            <NumberInput source="template_version"/>
            <TextInput source="description"/>
            <BooleanInput source="published"/>
            <TextInput source="label.de" label="Label DE"/>
            <TextInput source="label.en" label="Label EN"/>
        </SimpleForm>
    </Create>
);
